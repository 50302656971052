<template>
  <div v-if="loadingTable == true">
    <b-skeleton type="button" width="80px" class="skeleton-button"></b-skeleton>
    <div class="skeleton-wrapper">
      <div class="skeleton-search">
        <b-skeleton
          animation="wave"
          width="100px"
          class="skeleton-title"
        ></b-skeleton>
        <b-skeleton type="input" width="80rem"></b-skeleton>
      </div>
    </div>
    <div class="skeleton-wrapper">
      <div class="skeleton-search">
        <b-skeleton
          animation="wave"
          width="100px"
          class="skeleton-title"
        ></b-skeleton>
        <b-skeleton type="input" width="40rem"></b-skeleton>
      </div>
      <div class="skeleton-search">
        <b-skeleton
          animation="wave"
          width="100px"
          class="skeleton-title"
        ></b-skeleton>
        <b-skeleton type="input" width="40rem"></b-skeleton>
      </div>
    </div>
    <b-skeleton-table
      :rows="10"
      :columns="11"
      :table-props="{ bordered: true }"
    ></b-skeleton-table>
  </div>
  <div v-else>
    <div class="loading" v-if="isLoading == true">
      <div class="sk-three-bounce">
        <div class="sk-child sk-bounce1"></div>
        <div class="sk-child sk-bounce2"></div>
        <div class="sk-child sk-bounce3"></div>
      </div>
    </div>
    <b-row class="mb-3">
      <b-col sm>
        <div>
          <button class="btn btn-success" type="button" @click="exportTable">
            Export
          </button>
        </div>
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col sm="12">
        <div>
          <b-form-group class="mb-3">
            <label for="sp">
              Add Ons Name
            </label>
            <b-form-select
              id="sp"
              v-model="filterAddon"
              :options="addOnsOptions"
              @change="doFilterAddon"
            ></b-form-select>
          </b-form-group>
        </div>
      </b-col>
      <b-col sm="6">
        <div>
          <b-form-group
            class="mb-3"
            label="Search :"
            label-for="search"
            description="Searchable : Email, invoice number & add on code"
          >
            <b-input-group>
              <b-form-input
                id="search"
                type="search"
                v-model="filterText"
                placeholder="Email, invoice number, add on code"
                @change="doFilter"
              >
              </b-form-input>
              <b-input-group-append>
                <b-button
                  variant="secondary"
                  @click="resetFilter"
                  type="button"
                >
                  Reset
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>
      </b-col>
      <b-col sm="6">
        <div>
          <b-form-group class="mb-3" label="Date :" label-for="daterange">
            <b-input-group>
              <b-input-group-prepend is-text>
                <i class="fa fa-calendar"></i>
              </b-input-group-prepend>
              <range-picker
                id="daterange"
                :start="startDate"
                :end="endDate"
                :type="'addons'"
                @picker="doDateFilter"
              ></range-picker>
              <b-input-group-append>
                <b-button
                  type="button"
                  variant="secondary"
                  @click="resetDateFilter"
                  >Reset</b-button
                >
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col sm>
        <div>
          <button
            class="btn btn-alternate"
            :disabled="checkFilter()"
            type="button"
            @click="onFetch"
          >
            Apply Filter
          </button>
        </div>
      </b-col>
    </b-row>
    <vuetable
      ref="vuetable"
      :api-mode="false"
      :fields="fields"
      :per-page="limit"
      :data-manager="dataManager"
      :no-data-template="filtered ? defaultTemplate : noDataTemplate"
    >
      <template slot="add-ons-code-slot" slot-scope="prop">
        <span>{{ prop.rowData.add_on_code || "-" }}</span>
      </template>
      <template slot="add-ons-name-slot" slot-scope="prop">
        <span>{{ prop.rowData.add_on_name || "-" }}</span>
      </template>
      <template slot="user-name-slot" slot-scope="prop">
        <span>{{ prop.rowData.user_name || "-" }}</span>
      </template>
      <template slot="user-email-slot" slot-scope="prop">
        <span>{{ prop.rowData.user_email || "-" }}</span>
      </template>
      <template slot="created-date-slot" slot-scope="prop">
        <span>{{ prop.rowData.created_at || "-" }} </span>
      </template>
      <template slot="invoice-number-slot" slot-scope="prop">
        <span
          >{{ prop.rowData.invoice_number }}
          <i
            class="fa fa-copy icon-copy"
            @click="copyToClipboard(prop.rowData.invoice_number)"
          ></i>
        </span>
      </template>
      <template slot="total-amount-slot" slot-scope="prop">
        <span>{{ prop.rowData.total_amount }}</span>
      </template>
      <template slot="actions-slot" slot-scope="prop">
        <div class="custom-actions">
          <button
            type="button"
            class="btn btn-info m-1"
            @click="onAction('detail', prop.rowData)"
          >
            Detail
          </button>
        </div>
      </template>
    </vuetable>
    <div class="vuetable-pagination ui basic segment grid">
      <div class="wrapper-pagination-custom">
        <div class="pagination-custom-info">
          <p>{{ this.paginationInfo }}</p>
        </div>
        <div class="pagination-custom-button">
          <button :disabled="currentPage === 1" @click="doMovePage('first')">
            <i
              v-if="currentPage === 1"
              class="fa fa-angle-double-left disabled"
            ></i>
            <i v-else class="fa fa-angle-double-left"></i>
          </button>
          <button :disabled="currentPage === 1" @click="doMovePage('prev')">
            <i v-if="currentPage === 1" class="fa fa-angle-left disabled"></i>
            <i v-else class="fa fa-angle-left"></i>
          </button>
          <div>{{ this.currentPage }}</div>
          <button :disabled="data.length < 10" @click="doMovePage('next')">
            <i v-if="data.length < 10" class="fa fa-angle-right disabled"></i>
            <i v-else class="fa fa-angle-right"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import accounting from "accounting";
import Vuetable from "vuetable-2/src/components/Vuetable";
import dotenv from "dotenv";
import axios from "axios";
import _ from "lodash";
import $ from "jquery";

axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem(
  "access_token"
)}`;

dotenv.config();

let startDate = "";
let endDate = "";

Vue.use(Vuetable);

export default {
  components: {
    Vuetable
  },
  prop: {
    rowData: {
      type: Object,
      required: true
    },
    rowIndex: {
      type: Number
    }
  },
  data() {
    return {
      page: 1,
      currentPage: 1,
      limit: 10,
      data: [],
      dataLength: 0,
      paginationInfo: "",
      filterPartner: "",
      filterAddon: "",
      startDate: "",
      endDate: "",
      form: {
        npwpReason: ""
      },
      errors: {
        code: "",
        message: "",
        status: ""
      },
      warning_message: "",
      loadingTable: true,
      isLoading: false,
      baseUrlPath: process.env.VUE_APP_SECRET,
      from: "",
      selected: [],
      addOnsOptions: [{ value: "", text: "Select Addons" }],
      logModal: false,
      mydate: "",
      filterText: "",
      apiUrl:
        process.env.VUE_APP_SECRET +
        process.env.VUE_APP_CONFIG +
        `addons/transaction-history`,
      HttpOptions: {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("access_token")
        }
      },
      sort: "created_at|desc",
      sortOrder: [
        {
          field: "created_at",
          sortField: "created_at",
          direction: "desc"
        }
      ],
      moreParams: {},
      fields: [
        {
          name: "add-ons-code-slot",
          sortField: "add_on_code",
          title: "Add On Code"
        },
        {
          name: "add-ons-name-slot",
          sortField: "add_on_name",
          title: "Add On Name"
        },
        {
          name: "user-name-slot",
          sortField: "user_name",
          title: "Name"
        },
        {
          name: "user-email-slot",
          sortField: "user_email",
          title: "Email"
        },
        {
          name: "created-date-slot",
          sortField: "created_at",
          title: "Created Date"
        },
        {
          name: "invoice-number-slot",
          sortField: "invoice_number",
          title: "Invoice Number",
          dataClass: "center aligned",
          width: "25%"
        },
        {
          name: "total-amount-slot",
          sortField: "total_amount",
          title: "Total Amount"
        },
        {
          name: "actions-slot",
          title: "Actions",
          titleClass: "center aligned",
          dataClass: "center aligned"
        }
      ],
      filtered: false,
      noDataTemplate:
        '<div class="text-center"><p>Use filter to show your data.<p></div>',
      defaultTemplate:
        '<div class="text-center"><h5 class="mb-2">No results found.</h5><p>Please adjust your filter or search keywords.<p></div>'
    };
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    data(newVal, oldVal) {
      this.$refs.vuetable.refresh();
    }
  },
  created() {
    $('input[name="daterange"]').val("");
    // this.endDate = this.$setDate.format('YYYY-MM-DD');
    // this.startDate = this.$setDate.subtract(6, 'days').format('YYYY-MM-DD');
    this.$http.get(`addons/master-data`).then(response => {
      const newAddons = response.data.data.map(item => {
        return { value: item.value, text: item.name };
      });
      this.addOnsOptions = [...this.addOnsOptions, ...newAddons];
      this.loadingTable = false;
    });
  },
  methods: {
    onFetch(search = false) {
      const newParams = Object.entries(this.moreParams)
        .filter(([value]) => value !== "")
        .map(([key, value]) => `${key}=${value}`)
        .join("&");
      this.$http
        .get(
          `addons/transaction-history?page=${
            this.page
          }&per_page=10&${newParams}${
            search ? "" : "&sort=" + encodeURIComponent(this.sort)
          }`
        )
        .then(response => {
          const startIndex = (response.data.current_page - 1) * this.limit;
          const endIndex = startIndex + this.limit;
          this.data = response.data.data;
          this.currentPage = response.data.current_page;
          this.paginationInfo =
            response.data.data.length > 0
              ? `Displaying ${startIndex + 1} to ${endIndex}`
              : "No relevant data";
          this.filtered = true;
          if (this.loadingTable) this.loadingTable = false;
        })
        .catch(err => {
          this.handleLoadError(err);
        });
    },
    handleLoadError(error) {
      this.errors.code = error.response.data.meta.code;
      this.errors.message = error.response.data.meta.message;
      this.errors.status = error.response.data.meta.code;
      if (this.errors.code == 401) {
        if (localStorage.getItem("access_token") != null) {
          localStorage.removeItem("access_token");
          this.$swal
            .fire(
              "Your session expired!",
              "Your session has expired. Please login again to access this page!",
              "error"
            )
            .then(() => {
              this.$router.push("/login");
            });
        }
      } else if (this.errors.code == 403) {
        this.$router.push("/403");
      } else if (this.errors.code == 500) {
        this.$router.push("/500");
      }
    },
    doMovePage(value) {
      this.$events.$emit("movePage", value);
    },
    doFilter() {
      this.$events.$emit("filter-set", this.filterText);
    },
    doFilterAddon() {
      this.$events.$emit("addon-set", this.filterAddon);
    },
    resetFilter() {
      this.filterText = ""; // clear the text in text input
      this.$events.$emit("filter-reset");
    },
    exportTable() {
      this.isLoading = true;
      if (this.startDate != "" && this.endDate != "") {
        startDate = this.startDate;
        endDate = this.endDate;
      } else {
        endDate = this.$setDate.format("YYYY-MM-DD");
        startDate = this.$setDate.subtract(3, "days").format("YYYY-MM-DD");
      }
      const newParams = Object.entries(this.moreParams)
        .filter(([value]) => value !== "")
        .map(([key, value]) => `${key}=${value}`)
        .join("&");
      this.$http
        .get(`transaction/addons?${newParams}`)
        .then(result => {
          this.isLoading = false;
          const exportPath = result.data.meta.data;
          window.location.href = exportPath;
        })
        .catch(error => {
          if (error.response) {
            this.isLoading = false;
            this.errors.code = error.response.status;
            this.errors.status = error.response.data.meta.code;
            this.errors.headers = error.response.headers;
          }
        });
    },
    formatNumber(value) {
      return accounting.formatNumber(value, 2);
    },
    onChangePage(page) {
      this.$refs.vuetable.changePage(page);
    },
    onPaginationData(paginationData) {
      this.$refs.pagination.setPaginationData(paginationData);
      this.$refs.paginationInfo.setPaginationData(paginationData);
    },
    async onAction(action, data) {
      const routeData = this.$router.resolve({
        name: "Add Ons Transaction History Detail",
        params: { id: btoa(data.add_on_transaction_id) }
      });
      window.open(routeData.href, "_blank");
    },
    async onMovePage(eventData) {
      if (eventData === "first") {
        this.page = 1;
        this.onFetch();
      } else if (eventData === "prev") {
        this.page--;
        this.onFetch();
      } else {
        this.page++;
        this.onFetch();
      }
    },
    onFilterSet(filterText) {
      if (filterText) {
        const newParams = Object.fromEntries(
          Object.entries(this.moreParams).filter(([key]) => key !== "filter")
        );
        this.page = 1;
        this.moreParams = {
          ...newParams,
          filter: encodeURIComponent(filterText)
        };
      } else {
        this.moreParams = Object.fromEntries(
          Object.entries(this.moreParams).filter(([key]) => key !== "filter")
        );
      }
    },
    onAddonFilterSet() {
      if (this.filterAddon) {
        const newParams = Object.fromEntries(
          Object.entries(this.moreParams).filter(([key]) => key !== "addons_id")
        );
        this.page = 1;
        this.moreParams = {
          ...newParams,
          addons_id: this.filterAddon
        };
      } else {
        this.moreParams = Object.fromEntries(
          Object.entries(this.moreParams).filter(([key]) => key !== "addons_id")
        );
      }
    },
    doDateFilter(value) {
      this.startDate = value.startDate;
      this.endDate = value.endDate;
      this.$events.fire("date-set", [this.startDate, this.endDate]);
    },
    onDateSet() {
      this.start_from = this.startDate;
      this.end_to = this.endDate;
      const newParams = Object.fromEntries(
        Object.entries(this.moreParams).filter(
          ([key]) => key !== "start_from" && key !== "end_to"
        )
      );
      this.moreParams = {
        ...newParams,
        start_from: this.start_from,
        end_to: this.end_to
      };
      this.page = 1;
    },
    onFilterReset() {
      this.moreParams = Object.fromEntries(
        Object.entries(this.moreParams).filter(([key]) => key != "filter")
      );
      this.page = 1;
    },
    onDateFilterReset() {
      this.moreParams = Object.fromEntries(
        Object.entries(this.moreParams).filter(
          ([key]) => key !== "start_from" && key !== "end_to"
        )
      );
      this.page = 1;
    },
    resetDateFilter() {
      this.startDate = "";
      this.endDate = "";
      $('input[name="daterange"]').val("");
      this.$events.$emit("date-filter-reset");
    },
    dataManager(sortOrder, pagination) {
      let local = this.data;

      if (sortOrder.length > 0) {
        const newSort = `${sortOrder[0].sortField}|${sortOrder[0].direction}`;

        if (this.sort !== newSort) {
          this.sort = newSort;
          this.onFetch();
        }
      }

      pagination = this.$refs.vuetable.makePagination(local.length, this.limit);

      let from = pagination.from - 1;
      let to = from + this.limit;

      return {
        pagination: pagination,
        data: local.length > 0 ? _.slice(local, from, to) : []
      };
    },
    copyToClipboard(data) {
      navigator.clipboard.writeText(data);
      this.$bvToast.toast("Copy To Clipboard!", {
        title: "Info",
        variant: "primary",
        append: true,
        autoHideDelay: "1000"
      });
    },
    checkFilter() {
      if (
        this.filterPartner !== "" ||
        this.filterText !== "" ||
        this.filterAddon !== "" ||
        Object.entries(this.moreParams).filter(([key]) => key === "start_from")
          .length > 0
      ) {
        return false;
      }
      return true;
    }
  },
  mounted() {
    this.$events.$on("filter-set", eventData => this.onFilterSet(eventData));
    this.$events.$on("date-set", eventData => this.onDateSet(eventData));
    this.$events.$on("addon-set", eventData =>
      this.onAddonFilterSet(eventData)
    );
    this.$events.$on("filter-reset", () => this.onFilterReset());
    this.$events.$on("date-filter-reset", () => this.onDateFilterReset());
    this.$events.$on("movePage", eventData => this.onMovePage(eventData));
  }
};
</script>

<style lang="scss" scoped>
.warning {
  color: #e0a800;
}

.icon-copy {
  cursor: pointer;
}
.icon-copy:hover {
  color: #63c2de !important;
}

footer.modal-footer {
  display: none;
}
.mx-datepicker {
  width: 100%;
}
.mx-datepicker:disabled,
.mx-datepicker.disabled {
  background-color: #e4e7ea !important;
  opacity: 1 !important;
}
input.mx-inpu:disabled {
  background-color: #e4e7ea !important;
  opacity: 1 !important;
}
.mx-input:disabled,
.mx-input.disabled {
  background-color: #e4e7ea !important;
  opacity: 1 !important;
}
</style>
<style>
.btn-alternate {
  background: #7565f6 !important;
  color: #fff;
}

.btn-alternate:hover {
  background: #604cf8 !important;
  color: #fff !important;
}

.text-gray {
  color: rgb(128, 128, 128);
}

.text-info {
  color: #20a8d8;
}

.text-red {
  color: #cb211f;
}
.text-green {
  color: #35aa4b;
}
.text-warning {
  color: #a78f27;
}

/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

.v-select {
  padding-top: 23px;
}

/* Pagination Custom */
.wrapper-pagination-custom {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f9fafb;
}

.pagination-custom-button {
  display: flex;
}

.pagination-custom-button button {
  width: 28px;
  height: 47px;
  padding: 13px 16px;
  font-size: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border: solid rgba(0, 0, 0, 0.3);
}

.pagination-custom-button button:first-child {
  padding: 13px 16px 13px 30px;
  border-radius: 4px 0 0 4px;
  border-width: 1px 0 1px 1px;
}

.pagination-custom-button button:nth-child(2),
.pagination-custom-button button:last-child {
  font-size: 1.3em;
  border-width: 1px;
}

.pagination-custom-button button:last-child {
  border-radius: 0 4px 4px 0;
}

.pagination-custom-button button i.disabled {
  opacity: 0.6;
}

.pagination-custom-button div {
  width: 28px;
  height: 47px;
  font-size: 1em;
  font-weight: 500;
  background: #ffffff;
  border: solid rgba(0, 0, 0, 0.3);
  border-width: 1px 0 1px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Skeleton */
.skeleton-button,
.skeleton-type,
.skeleton-wrapper {
  margin-bottom: 20px;
}

.skeleton-title {
  height: 18px;
  margin-bottom: 10px;
}

.skeleton-ktp,
.skeleton-selfie,
.skeleton-filter,
.skeleton-date {
  margin-right: 30px;
}

.skeleton-wrapper {
  display: grid;
  grid-template-columns: auto auto auto;
}
</style>
<style src="spinkit/scss/spinkit.scss" lang="scss" />
